import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

const AgaveHero = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "Metals06.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )


  return (
     <GatsbyImage
        style={{
          maxWidth: 360,
        }}
        className="logoimage"
        layout="fullWidth"
        aspectatio={3 / 1}
        alt="Cover"
        placeholder="none"
        loading="eager"
        imgStyle={{height: 'initial', maxHeight: '360px'}}
        image={data.desktop.childImageSharp.gatsbyImageData}
        formats={["auto", "webp", "avif"]}
      />
  )
}

export default AgaveHero