import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

const RailsCoverXS = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "rails-cover-xs.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )


  return (
     <GatsbyImage
        
        style={{
          maxWidth: '100%',
          position: 'absolute',
          top: 0,
          zIndex: -20
        }}
      className="logoimage"
        layout="fullWidth"
        aspectatio={3 / 1}
        alt="Cover"
        placeholder="none"
        loading="eager"
        imgStyle={{height: 'initial', maxHeight: '100%'}}
        image={data.desktop.childImageSharp.gatsbyImageData}
        formats={["auto", "webp", "avif"]}
      />
  )
}

export default RailsCoverXS