import React, { useState, useRef } from "react"
import Layout from '../components/layout'
import Seo from '../components/seo'
import AgaveLogo from '../images/svg/agave.svg';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import PurpleGlow from '../images/svg/bg-purple.svg';
import Common from '../components/common'

import Metals01 from '../components/metals01'
import Metals02 from '../components/metals02'
import Metals03 from '../components/metals03'
import Metals04 from '../components/metals04'
import Metals05 from '../components/metals05'
import Metals06 from '../components/metals06'
import Metals07 from '../components/metals07'
import Metals08 from '../components/metals08'




import Metals from '../components/metals'
import Grain from '../components/grain'
import Agricultural from '../components/agricultural'
import Animal from '../components/animal'
import Energy from '../components/energy'
import ArrowUp from '../images/svg/arrow-up.svg';
import NavOpen from '../images/svg/nav-menu-open.svg';
import NavClose from '../images/svg/nav-menu-close.svg';
import 'intersection-observer'
import { useIsVisible } from 'react-is-visible'
import AgaveHero from '../components/agaveHero'
import FinancialCover from '../components/finalcialCover'
import FinalcialCoverXS from '../components/financialCoverXS'
import PpeCover from '../components/ppeCover'
import PpeCoverXS from '../components/ppeCoverXS'
import HotelCover from '../components/hotelCover'
import HotelCoverXS from '../components/hotelCoverXS'
import RailsCover from '../components/railsCover'
import RailsCoverXS from '../components/railsCoverXS'
import { SocialIcon } from 'react-social-icons';

const IndexPage = () => { 
  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const breakpoints = useBreakpoint();
  const [open, setOpen] = useState(false);

  const ScrollButton = () => {
    if(typeof window !== 'undefined'){
      if (window.scrollY > 0) {
        return (
          <a href="#goup" className={`scrollup ${breakpoints.sm ? 'scrollup-sm' : ''} `}  style={(isVisible) ? {opacity: 0, display: 'hidden'} : {opacity: 1, display: 'flex'}}>
                <ArrowUp className="text-purple-500"/>
          </a>
        );
      }
    } else {
      return
    }
  }
  
  const handleOpen = (open) => {
    setOpen(!open)
  }

  return (
  <Layout>
    <Seo title="AGAVE" description="Consultancy, Marketing & Commodities"/>
    {/* SECTION HERO */}
    <div style={open ? {transform: 'translateY(100%)'} : {transform: 'translateY(0px)'}} className="animnav fixed overflow-hidden top-[-100%] h-full left-0 right-0 w-full bg-[purple] z-[999]">
      <div className="absolute top-0 left-0 right-0 bottom-0 py-7 px-9">
        <a href="/">
          <div className="text-left whitespace-nowrap">    
            <span className="block inline-flex mr-4" aria-label="Merix Logo">
              <AgaveLogo/>
            </span>
            <span className="font-sans inline-flex align-top leading-[58px] font-bold text-2xl text-white">AGAVE</span>
          </div>
        </a>
        <div className="pt-10 font-sans">
          <a href="#aboutus" onClick={(open) => handleOpen(open)} className="block py-4 text-white hover:text-purple-400 md:hover:text-purple-400 md:p-0 text-3xl">About Us</a>
          <a href="#rails" onClick={(open) => handleOpen(open)} className="block py-4 text-white hover:text-purple-400 md:hover:text-purple-400 md:p-0 text-3xl">Metals & Steel</a>
          <a href="#ppe" onClick={(open) => handleOpen(open)} className="block py-4 text-white hover:text-purple-400 md:hover:text-purple-400 md:p-0 text-3xl">PPE</a>
          <a href="#commodities" onClick={(open) => handleOpen(open)} className="block py-4 text-white hover:text-purple-400 md:hover:text-purple-400 md:p-0 text-3xl">Commodities</a>
          {/*<a href="#financial" onClick={(open) => handleOpen(open)} className="block py-4 text-white hover:text-purple-400 md:hover:text-purple-400 md:p-0 text-3xl">Financial</a>*/}
          <a href="#realestate" onClick={(open) => handleOpen(open)} className="block py-4 text-white hover:text-purple-400 md:hover:text-purple-400 md:p-0 text-3xl">Real Estate</a>
          <a href="mailto:info@agave.pt" target="_blank" rel="noreferrer" onClick={(open) => handleOpen(open)} className="block py-4 text-white hover:text-purple-400 md:hover:text-purple-400 md:p-0 text-3xl">Contact Us</a>
        </div>
      </div>
    </div>
    <div style={open ? {position: 'fixed'} : {position: 'absolute'}} className="nav-menu-btn block md:hidden">
          <div
            onClick={() => handleOpen(open)}
            onKeyDown={() => {return}}
            rel="noopener noreferrer"
            role="button"
            tabIndex={0}
            >
            {open ? <NavClose/> : <NavOpen/>}
          </div>
    </div>
    <section id="goup" className="relative" ref={nodeRef}>
      <div className="relative overflow-hidden border-b border-gray-700">
        <PurpleGlow className="heroglow opacity-1"/>
        <div className="relative mx-auto flex items-center justify-center text-center w-full h-500 z-10">
          <div className="mt-20 w-full h-full px-10 text-white">
            <div className="grid grid-cols-2 gap-2">
             {/* LOGO START */}
              <a href="/">
                <div className="text-left whitespace-nowrap">    
                  <span className="block inline-flex mr-4" aria-label="Merix Logo">
                    <AgaveLogo/>
                  </span>
                  <span className="font-sans inline-flex align-top leading-[58px] font-bold text-2xl">AGAVE</span>
                </div>
              </a>
             {/* LOGO END */}
              <div className="text-right leading-[58px]">
                {/* NAVBAR START */}
                <nav className="text-right px-2 sm:pl-4 sm:pr-0">
                  <div className="container flex flex-wrap justify-end items-center mx-auto">
                    <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                      <ul className="flex flex-col p-4 mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium pr-0">
                        <li>
                          <a href="#aboutus" className="block py-2 pr-4 pl-3 text-blue hover:text-purple-400 md:hover:text-purple-400 md:p-0 whitespace-nowrap">About Us</a>
                        </li>
                        <li>
                          <a href="#rails" className="block py-2 pr-4 pl-3 text-blue hover:text-purple-400 md:hover:text-purple-400 md:p-0 whitespace-nowrap">Metals & Steel</a>
                        </li>
                        <li>
                          <a href="#ppe" className="block py-2 pr-4 pl-3 text-blue hover:text-purple-400 md:hover:text-purple-400 md:p-0 whitespace-nowrap">PPE</a>
                        </li>
                        <li>
                          <a href="#commodities" className="block py-2 pr-4 pl-3 text-blue hover:text-purple-400 md:hover:text-purple-400 md:p-0 whitespace-nowrap">Commodities</a>
                        </li>
{/*
                        <li>
                          <a href="#financial" className="block py-2 pr-4 pl-3 text-blue hover:text-purple-400 md:hover:text-purple-400 md:p-0 whitespace-nowrap">Financial</a>
                        </li>
*/}
                        <li>
                          <a href="#realestate" className="block py-2 pr-4 pl-3 text-blue hover:text-purple-400 md:hover:text-purple-400 md:p-0 whitespace-nowrap">Real Estate</a>
                        </li>
                        <li>
                          <a href="mailto:info@agave.pt" target="_blank" rel="noreferrer" className="block py-2 pr-4 pl-3 text-blue hover:text-purple-400 md:hover:text-purple-400 md:p-0 whitespace-nowrap">Contacts</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
                {/* NAVBAR END */}
              </div>
            </div>
          </div>
        </div>
        {/* HERO LEFT START */}
        <div className="grid text-white mt-10 mx-10 grid-flow-col">
          <div className="flex-auto max-w-2xl mt-20">
            <h1 className="leading-snug mb-10 md:text-3xl lg:text-5xl">A future ecosystem benchmark in the commodity market</h1>
            <p className="max-w-lg leading-relaxed text-neutral-300">Recycle Metals & Steel | Common and precious metals | Energy | Agricultural | Financial | Personal Protection Equipment | Real Estate Hospitality - Be one more person to enjoy our services</p>
              <ul className="flex flex-col md:flex-row items-center">
              <li className="p-2 sm:p-3">
                <a
                    href="https://wa.me/351962555030"
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex border-rainbow-gradient border-double border-4 border-transparent rounded-full bg-origin-border font-bold hover:scale-105 transition-all duration-500 hover:text-gray-200"
                >
                  <span className="select-none text-white text-sm text-lg block p-2 m-0">
                    Whatsapp
                  </span>
                </a>
              </li>
                <li className="p-2 sm:p-3">
                  <SocialIcon url="https://www.linkedin.com/company/36155823" target="_blank"/>
                </li>
                <li className="p-2 sm:p-3">
                  <SocialIcon url="https://facebook.com/agave.commodities" target="_blank"/>
                </li>
                <li className="p-2 sm:p-3">
                  <SocialIcon url="https://www.instagram.com/agave.commodities" target="_blank"/>
                </li>


                
              </ul>


          </div>
          {/* HERO LEFT END */}
          {/* HERO RIGHT START */}
          <div className="max-w-[700px] w-full flex items-end">
            <AgaveHero className="relative right-[-100px] hidden sm:block"/>
          </div>
          {/* HERO RIGHT END */}
        </div>
      </div>
    </section>
    {/* SECTION PROJECT */}
        <section>
          <div id="aboutus">
            <div  className="bg-[#600061] w-full grid gap-12 grid gap-12 text-white justify-items-center text pt-20 pb-20 border-b border-gray-700">
            <div className="max-w-5xl grid gap-8 justify-items-center text-center px-10">
                <div className="grid gap-8 justify-items-center text-center">
                  <div className="text-center grid gap-2">
                    <h3 className="text-3xl sm:text-5xl font-black p-1 text-hero-gradient">About us</h3>
                  </div>
                  <div>
                    <p className="leading-relaxed text-neutral-300 text-md sm:text-md pb-2">
                      This company was founded at the beginning of 2020 by two people with functions that complement each other in areas as diverse as management consultancy, digital marketing, consultancy, corporate or institutional advertising, communication, social consultancy or access to community funds.
                    </p>
                    <p className="leading-relaxed text-neutral-300 text-md sm:text-md pb-2">
                      Today, AGAVE specializes in commodities such as precious metals, ferrous and non-ferrous, some products from the agricultural group of listed commodities, as well as animal commodities. In terms of energy, we have know-how in oil and its derivatives.
                    </p>
                    <p className="leading-relaxed text-neutral-300 text-md sm:text-md pb-2">
                      We are mandates or intermediaries for various suppliers and buyers.</p>
                      <p className="leading-relaxed text-neutral-300 text-md sm:text-md pb-2">
                      We have a department dedicated to hotel transactions.
                    </p>
                    <p className="leading-relaxed text-neutral-300 text-md sm:text-md pb-2">
                      We also have a department exclusively linked to the steel sector through the mandate with several suppliers of used or second quality rails R-50 & R-65.
                    </p>
                    <p className="leading-relaxed text-neutral-300 text-md sm:text-md pb-2">
                      We are constantly adapting and, due to market circumstances, we have created a department exclusively linked to Personal Protective Equipment (PPE), such as face masks and nitrile gloves from the most diverse brands, acting as agents for several suppliers.
                    </p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>

    {/* SECTION PROJECT */}
        <section>
          <div id="rails" className="relative overflow-hidden">
          <div  className="w-full grid gap-12 grid gap-12 text-white justify-items-center text pt-20 pb-20 border-b border-gray-700">
            <div className="max-w-5xl grid gap-8 justify-items-center text-center px-10">
                <div className="grid gap-8 justify-items-center text-center">
                  <div className="text-center grid gap-2">
                    <h3 className="text-3xl sm:text-5xl font-black p-1 text-hero-gradient">Recycle Metals & Steel</h3>
                  </div>
                  <div>
                    <p className="leading-relaxed text-neutral-300 text-xs sm:text-sm pb-2">
                      We represent several suppliers from Saudi Arabia, USA, Canada, Central & South America of used rails HMS2, steel billets, rebars, hot-rolled coils, cold-rolled coils, HMS 1&2, aluminum scrap and copper wire scrap/copper scrap.
                    </p>
                    <p className="leading-relaxed text-neutral-300 text-xs sm:text-sm pb-2">
                      Payment is made after SGS inspection or similar at destination port.
                    </p>
                    <div>
                    </div>
                  </div>
                </div>
            </div>
          { breakpoints.sm ? <RailsCoverXS/> :<RailsCover/>}
          <div className="absolute inset-0 bg-black opacity-70 -z-10"></div>
          </div>
        </div>
      </section>

 <section>
        <div id="">
          <div className="bg-[#600061] relative container max-w-7xl mx-auto px-0 sm:px-8 py-10 border-t border-b border-gray-700">
            <div className="grid gap-16 text-white max-w-[1040px] mx-auto">
              <div className="grid gap-8 justify-items-center text-center">
                <div className="grid sm:grid-cols-4 gap-12 sm:gap-4 w-full h-full">
                  <div className="relative p-4 border-y-2 sm:border-2 border-[#ffc107] sm:rounded-2xl">
                    <div className="absolute w-20 h-2 bg-[#ffc107] rounded-b-lg top-0 left-0 right-0 mx-auto"></div>
                    <div>
                      <div className="p-5">
                        <div className="text-[#c45bff] rounded-full overflow-hidden">
                          <Metals01 className="w-40 sel-center inline-flex"/>
                        </div>
                      </div>
                      <div>
                        <h3 className="leading-relaxed p-4 mb-2">
                          Used Rails R50-R65
                        </h3>
                      </div>
                      <div>
                        <p className="leading-relaxed text-neutral-300">
                          We represent sellers from Saudi Arabia, Switzerland.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="relative p-4 border-y-2 sm:border-2 border-[#ffc107] sm:rounded-2xl">
                    <div className="absolute w-20 h-2 bg-[#ffc107] rounded-b-lg top-0 left-0 right-0 mx-auto"></div>
                    <div>
                      <div className="p-5">
                        <div className="text-[#c45bff] rounded-full overflow-hidden">
                          <Metals02 className="w-40 sel-center inline-flex"/>
                        </div>
                      </div>
                      <div>
                        <h3 className="leading-relaxed p-4 mb-2">
                          HMS 1&2
                        </h3>
                      </div>
                      <div>
                        <p className="leading-relaxed text-neutral-300">
                          We represent sellers from USA, Canada, Central & South America.
                        </p>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                  <div className="relative p-4 border-y-2 sm:border-2 border-[#ffc107] sm:rounded-2xl">
                    <div className="absolute w-20 h-2 bg-[#ffc107] rounded-b-lg top-0 left-0 right-0 mx-auto"></div>
                    <div>
                      <div className="p-5">
                        <div className="text-[#c45bff] rounded-full overflow-hidden">
                          <Metals03 className="w-40 sel-center inline-flex"/>
                        </div>
                      </div>
                      <div>
                        <h3 className="leading-relaxed p-4 mb-2">
                          Aluminum Scrap
                        </h3>
                      </div>
                      <div>
                        <p className="leading-relaxed text-neutral-300">
                          We represent sellers from USA, Canada, Central & South America.
                        </p>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>

                  <div className="relative p-4 border-y-2 sm:border-2 border-[#ffc107] sm:rounded-2xl">
                    <div className="absolute w-20 h-2 bg-[#ffc107] rounded-b-lg top-0 left-0 right-0 mx-auto"></div>
                    <div>
                      <div className="p-5">
                        <div className="text-[#c45bff] rounded-full overflow-hidden">
                          <Metals04 className="w-40 sel-center inline-flex"/>
                        </div>
                      </div>
                      <div>
                        <h3 className="leading-relaxed p-4 mb-2">
                          Copper Wire Scrap / Copper Scrap
                        </h3>
                      </div>
                      <div>
                        <p className="leading-relaxed text-neutral-300">
                          We represent sellers from USA, Canada, Central & South America.
                        </p>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>

                  <div className="relative p-4 border-y-2 sm:border-2 border-[#ffc107] sm:rounded-2xl">
                    <div className="absolute w-20 h-2 bg-[#ffc107] rounded-b-lg top-0 left-0 right-0 mx-auto"></div>
                    <div>
                      <div className="p-5">
                        <div className="text-[#c45bff] rounded-full overflow-hidden">
                          <Metals05 className="w-40 sel-center inline-flex"/>
                        </div>
                      </div>
                      <div>
                        <h3 className="leading-relaxed p-4 mb-2">
                          Steel Billets
                        </h3>
                      </div>
                      <div>
                        <p className="leading-relaxed text-neutral-300">
                          We represent sellers from Saudi Arabia & Switzerland.
                        </p>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>

                  <div className="relative p-4 border-y-2 sm:border-2 border-[#ffc107] sm:rounded-2xl">
                    <div className="absolute w-20 h-2 bg-[#ffc107] rounded-b-lg top-0 left-0 right-0 mx-auto"></div>
                    <div>
                      <div className="p-5">
                        <div className="text-[#c45bff] rounded-full overflow-hidden">
                          <Metals06 className="w-40 sel-center inline-flex"/>
                        </div>
                      </div>
                      <div>
                        <h3 className="leading-relaxed p-4 mb-2">
                          Rebar GR-60
                        </h3>
                      </div>
                      <div>
                        <p className="leading-relaxed text-neutral-300">
                          We represent sellers from Saudi Arabia & Switzerland.
                        </p>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>


                  <div className="relative p-4 border-y-2 sm:border-2 border-[#ffc107] sm:rounded-2xl">
                    <div className="absolute w-20 h-2 bg-[#ffc107] rounded-b-lg top-0 left-0 right-0 mx-auto"></div>
                    <div>
                      <div className="p-5">
                        <div className="text-[#c45bff] rounded-full overflow-hidden">
                          <Metals07 className="w-40 sel-center inline-flex"/>
                        </div>
                      </div>
                      <div>
                        <h3 className="leading-relaxed p-4 mb-2">
                          Hot-Rolled Coils
                        </h3>
                      </div>
                      <div>
                        <p className="leading-relaxed text-neutral-300">
                          We represent sellers from Saudi Arabia & Switzerland.
                        </p>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>

                  <div className="relative p-4 border-y-2 sm:border-2 border-[#ffc107] sm:rounded-2xl">
                    <div className="absolute w-20 h-2 bg-[#ffc107] rounded-b-lg top-0 left-0 right-0 mx-auto"></div>
                    <div>
                      <div className="p-5">
                        <div className="text-[#c45bff] rounded-full overflow-hidden">
                          <Metals08 className="w-40 sel-center inline-flex"/>
                        </div>
                      </div>
                      <div>
                        <h3 className="leading-relaxed p-4 mb-2">
                          Cold-Rolled Coils
                        </h3>
                      </div>
                      <div>
                        <p className="leading-relaxed text-neutral-300">
                          We represent sellers from Saudi Arabia & Switzerland.
                        </p>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

















      

      <section className="relative overflow-hidden">
        <div id="ppe">
          <div  className="w-full grid gap-12 grid gap-12 text-white justify-items-center text pt-20 pb-20 border-b border-gray-700">
            <div className="max-w-5xl grid gap-8 justify-items-center text-center px-10">
              <div className="text-center grid gap-2">
                <h3 className="leading-relaxed text-3xl sm:text-5xl font-black p-1 text-hero-gradient">
                  PPE - Personal Protection Equipment
                </h3>
              </div>
              <p className="leading-relaxed text-neutral-300 text-xs sm:text-sm pb-2">We have suppliers of nitrile gloves, latex and masks of various models with all the necessary certifications and also for large volumes.</p>
            </div>
          </div>
          { breakpoints.sm ? <PpeCoverXS/> : <PpeCover/>}
          <div className="absolute inset-0 bg-black opacity-50 -z-10"></div>
        </div>
      </section>
      {/* SECTION COMMUNITY */}
      <section className="relative overflow-hidden">
        <div id="realestate">
          <div  className="w-full grid gap-12 grid gap-12 text-white justify-items-center text pt-20 pb-20 border-b border-gray-700">
            <div className="max-w-5xl grid gap-8 justify-items-center text-center px-10">
              <div className="text-center grid gap-2">
                <h3 className="leading-relaxed text-3xl sm:text-5xl font-black p-1 text-hero-gradient">
                  Real Estate - Hospitality
                </h3>
              </div>
              <p className="leading-relaxed text-neutral-300 text-xs sm:text-sm pb-2">We have a remarkable database of buyers, as well as we represent some hotel group sellers or mandates</p>
            </div>
          </div>
          { breakpoints.sm ? <HotelCoverXS/> : <HotelCover/>}
          <div className="absolute inset-0 bg-black opacity-70 -z-10"></div>
        </div>
      </section>

      <section>
        <div id="commodities">
          <div className="bg-[#600061] relative container max-w-7xl mx-auto px-0 sm:px-8 py-10 border-t border-b border-gray-700">
            <div className="grid gap-16 text-white max-w-[1040px] mx-auto">
              <div className="grid gap-8 justify-items-center text-center">
                <div className="text-center grid gap-2">
                  <h3 className="text-3xl sm:text-5xl font-black p-1 text-hero-gradient">Commodities</h3>
                </div>
                <div>
                  <p className="text-xl sm:text-s leading-relaxed mb-2 px-8 text-neutral-300">We have suppliers/buyers for the acquisition of the following types of Commodities:</p>
                </div>
                <div className="grid sm:grid-cols-3 gap-12 sm:gap-4 w-full h-full">
                  <div className="relative p-4 border-y-2 sm:border-2 border-[#ffc107] sm:rounded-2xl">
                    <div className="absolute w-20 h-2 bg-[#ffc107] rounded-b-lg top-0 left-0 right-0 mx-auto"></div>
                    <div>
                      <div className="p-5">
                        <div className="text-[#c45bff">
                          <Common className="w-40 sel-center inline-flex"/>
                        </div>
                      </div>
                      <div>
                        <h3 className="leading-relaxed p-4 mb-2">
                          Common Metals
                        </h3>
                      </div>
                      <div>
                        <p className="leading-relaxed text-neutral-300">
                          Aluminium, Copper, Iron Ore, Lead, Lithium, Nickel, Zinc, Manganese, Steel, Milberry Copper Wire and HMS1&2.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="relative p-4 border-y-2 sm:border-2 border-[#ffc107] sm:rounded-2xl">
                    <div className="absolute w-20 h-2 bg-[#ffc107] rounded-b-lg top-0 left-0 right-0 mx-auto"></div>
                    <div>
                      <div className="p-5">
                        <div className="text-[#c45bff">
                          <Metals className="w-40 sel-center inline-flex"/>
                        </div>
                      </div>
                      <div>
                        <h3 className="leading-relaxed p-4 mb-2">
                          Precious Metals
                        </h3>
                      </div>
                      <div>
                        <p className="leading-relaxed text-neutral-300">
                          Gold 
                        </p>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                  <div className="relative p-4 border-y-2 sm:border-2 border-[#ffc107] sm:rounded-2xl">
                    <div className="absolute w-20 h-2 bg-[#ffc107] rounded-b-lg top-0 left-0 right-0 mx-auto"></div>
                    <div>
                      <div className="p-5">
                        <div className="text-[#c45bff">
                          <Agricultural className="w-40 sel-center inline-flex"/>
                        </div>
                      </div>
                      <div>
                        <h3 className="leading-relaxed p-4 mb-2">
                          Agricultural
                        </h3>
                      </div>
                      <div>
                        <p className="leading-relaxed text-neutral-300">
                          Urea, Soybean, Palm Oil, Olive Oil, Sunflower Oil, Soybean Oil and Sugar;
                        </p>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>

                  <div className="relative p-4 border-y-2 sm:border-2 border-[#ffc107] sm:rounded-2xl">
                    <div className="absolute w-20 h-2 bg-[#ffc107] rounded-b-lg top-0 left-0 right-0 mx-auto"></div>
                    <div>
                      <div className="p-5">
                        <div className="text-[#c45bff">
                          <Grain className="w-40 sel-center inline-flex"/>
                        </div>
                      </div>
                      <div>
                        <h3 className="leading-relaxed p-4 mb-2">
                          Grain
                        </h3>
                      </div>
                      <div>
                        <p className="leading-relaxed text-neutral-300">
                          Rice, Wheat and Corn;
                        </p>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>

                  <div className="relative p-4 border-y-2 sm:border-2 border-[#ffc107] sm:rounded-2xl">
                    <div className="absolute w-20 h-2 bg-[#ffc107] rounded-b-lg top-0 left-0 right-0 mx-auto"></div>
                    <div>
                      <div className="p-5">
                        <div className="text-[#c45bff">
                          <Animal className="w-40 sel-center inline-flex"/>
                        </div>
                      </div>
                      <div>
                        <h3 className="leading-relaxed p-4 mb-2">
                          Animal
                        </h3>
                      </div>
                      <div>
                        <p className="leading-relaxed text-neutral-300">
                          Pork belly and lean pigs
                        </p>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                  <div className="relative p-4 border-y-2 sm:border-2 border-[#ffc107] sm:rounded-2xl">
                    <div className="absolute w-20 h-2 bg-[#ffc107] rounded-b-lg top-0 left-0 right-0 mx-auto"></div>
                    <div>
                      <div className="p-5">
                        <div className="text-[#c45bff">
                          <Energy className="w-40 sel-center inline-flex"/>
                        </div>
                      </div>
                      <div>
                        <h3 className="leading-relaxed p-4 mb-2">
                          Energy
                        </h3>
                      </div>
                      <div>
                        <p className="leading-relaxed text-neutral-300">
                          Coal, Gas (LPG & LNG) and Oil & derivatives (Jet fuel – A1/JP54, D6, D2, M100, Fuel Oil, Gasoline, EN590, Base Oil & BLCO), Bitumen, Urea, Pet Coke, ESPO, REBCO
                        </p>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* SECTION FINANTIAL */}
      {/*
      <section className="relative overflow-hidden">
        <div id="financial">
          <div  className="w-full grid gap-12 grid gap-12 text-white justify-items-center text pt-20 pb-20 border-b border-gray-700">
            <div className="max-w-5xl grid gap-8 justify-items-center text-center px-10">
              <div className="text-center grid gap-2">
                <h3 className="leading-relaxed text-3xl sm:text-5xl font-black p-1 text-hero-gradient">
                  Financial
                </h3>
              </div>
              <p className="leading-relaxed text-neutral-300 text-xs sm:text-sm pb-2">We have a department specialized in the transaction of Bitcoins (BTC), Corporate Bonds & Historical Bonds.</p>
              </div>
          </div>
          { breakpoints.sm ? <FinalcialCoverXS/> : <FinancialCover/>}
          <div className="absolute inset-0 bg-black opacity-70 -z-10"></div>
        </div>
      </section>
      */
      }

      {/* SECTION COMMUNITY */}


      <ScrollButton/>
  </Layout>
)}

export default IndexPage
